<template>
  <component
    :is="wrapperComponent"
    :to="to"
    class="icon-button"
    :class="{
      'icon-button--active': active,
      'icon-button--small': small,
      'icon-button--smallest': smallest,
    }"
  >
    <icon
      :name="name"
      size="16"
      class="icon-button__icon"
    />
  </component>
</template>

<script setup>
  import NuxtLink from '#app/components/nuxt-link'

  const props = defineProps({
    name: {
      type: String,
      default: 'octicon:device-mobile',
    },
    active: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    smallest: {
      type: Boolean,
      default: false,
    },
  })

  const wrapperComponent = props.to ? NuxtLink : 'button'
</script>

<style lang="scss">
  .icon-button {
    background: var(--color-light-gray);
    color: var(--color-gray);
    border-radius: 4px;
    transition: all .2s;
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-button:hover:not(.icon-button--active) {
    background: color-mix(in srgb, var(--color-light-gray), #000 10%);
  }

  .icon-button--active {
    background: var(--color-primary);
    color: white;
  }

  .icon-button--small {
    width: 33px;
    height: 33px;
  }

  .icon-button--smallest {
    width: 22px;
    height: 22px;
  }
</style>
